.quote-section .quote {
  @apply relative border-y-2 py-12 lg:py-36 pl-10 xs:pl-20;
}
.quote-section .quote:before, .quote-section .quote:after {
  content: "";
  @apply w-[7px] bg-green absolute left-0 top-12 lg:top-24 bottom-12 lg:bottom-24;
}
.quote-section .quote:after {
  @apply left-4;
}
.quote-section .quote-title {
  @apply text-2xl xs:text-4xl 4xl:text-5xl tracking-normal mb-0;
}
.quote-section .swiper {
  @apply pb-12;
}
.quote-section .swiper-pagination-bullets {
  @apply bottom-0 text-left;
}
.quote-section .swiper-pagination-bullets .swiper-pagination-bullet {
  @apply rounded-none bg-body w-3 h-3;
}
.quote-section .swiper-pagination-bullets .swiper-pagination-bullet-active {
  @apply bg-body;
}